import React, { useEffect, useState } from "react";
import HeroPanel from "../../components/HeroPanel/HeroPanel";
import { DOCKER_WORKER } from "../../PageContent/DockerWorker";
import Margins from "../../components/Margins/Margins";
import CTAPanel from "../../components/CTA/CTAPanel";
import WorkerCard from "../../components/WorkerCard/WorkerCard";
import Tabs from "../../components/Tabs/Tabs";
import NumberGlassCard from "../../components/Cards/NumberGlassCard";
import CardPanel from "../../components/CardPanel/CardPanel";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import ManifestYamlModal from "../../components/ManifestYamlModal/ManifestYamlModal";

function DockerWorkers() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });

    return () => {
      setModalOn(false);
    };
  }, []);
  const [modalOn, setModalOn] = useState(false);

  const copyText = (message: any) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        console.log("Text copied to clipboard: " + message);
        notify("Copied text");
      })
      .catch((e) => {
        console.log("unable to copy text: ", e);
        notify("Failed to copy message");
      });
  };
  const notify = (message: string) => {
    toast(message, { toastId: 1, closeOnClick: false });
  };
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={DOCKER_WORKER?.metadata?.description}
        />
        <meta name="keywords" content={DOCKER_WORKER?.metadata?.keywords} />
      </Helmet>
      {/* ------------------------------------------------- Hero Panel ------------------------------------------------- */}
      <HeroPanel
        title={DOCKER_WORKER.hero.title}
        description={DOCKER_WORKER?.hero.description}
        bgImage={DOCKER_WORKER?.hero.bgImage}
        minHeight="!min-h-[700px] !2xl:min-h-[700px]"
        contentClassName="relative bottom-[90px]"
      ></HeroPanel>

      {/* ------------------------------------------------- DOCKER WORKER & KUBERNETES MANIFEST & HELM CHART ------------------------------------------------- */}
      <Margins>
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center items-center gap-[20px] lg:gap-[40px] xl:gap-[120px] relative my-[20px] xl:mt-[-200px]">
          {DOCKER_WORKER?.howToGetStarted.cards.map((card, key) => (
            <WorkerCard
              key={key}
              toastTrigger={notify}
              content={card}
              borderCardClassName="w-[100%] min-h-[450px] h-[100%] xl:justify-self-center "
              cardClassName="m-[0]"
              modalOn={modalOn}
              setModalOn={setModalOn}
            >
              {card.comingSoon && <p>{card.comingSoon}</p>}
            </WorkerCard>
          ))}
        </div>
      </Margins>

      {/* ------------------------------------------------- How to get started ------------------------------------------------- */}
      <Tabs
        tabs={DOCKER_WORKER?.tabs}
        tabClassName="mb-0"
        tabsWork={true}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      >
        {/* Video */}
        {/* <CardPanel
            textBlack={true}
            // title={DOCKER_WORKER?.installationProcedures[activeTab].pageHeader}
            // subHeading={
            //   DOCKER_WORKER?.installationProcedures[activeTab].description
            // }
            parentDivClassName="pb-0"
          >
            {DOCKER_WORKER?.installationProcedures[activeTab].video && (
              <iframe
                className={`screensaver-video mt-[25px] w-[100%] lg:w-[900px] xl:w-[1245px] h-[350px] sm:h-[450px] lg:h-[500px] xl:h-[620px] z-[2] ${STYLES.screenSaverVideo}`}
                src={DOCKER_WORKER?.installationProcedures[activeTab].video}
                title="How to get started with the screensaver worker"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                width="560"
                height="315"
              ></iframe>
            )}
          </CardPanel> */}
        {/* ------------------------------------------------- Installation Procedure ------------------------------------------------- */}
        <CardPanel
          title={DOCKER_WORKER?.installationProcedures[activeTab].title}
          titleClassName="text-[black]"
          parentDivClassName="bg-[#E6E6E6] w-[100%] py-[40px]"
          childrenDivClassName=" flex flex-col gap-[3rem]"
        >
          <div className="max-w-[3500px] grid grid-cols-1 lg:grid-cols-3 gap-[40px] pt-[80px] w-[92%] mx-auto justify-center items-stretch">
            {/* Step 1 */}
            <NumberGlassCard
              src={
                DOCKER_WORKER?.installationProcedures[activeTab].step1?.number
              }
              divClassName="!bg-[#FFF] xl:max-w-[500px] !w-[100%] box-border mx-auto"
            >
              <div className="mt-[37px]  xl:max-w-[380px] px-[0] xl:px-[0] pb-[20px] xl:pb-[0px] xl:mx-auto flex flex-col justify-start items-center lg:items-start w-[100%]">
                <div className="!text-[black] text-center lg:text-left mb-[27px] w-[100%]">
                  <p>
                    {parse(
                      DOCKER_WORKER?.installationProcedures[activeTab].step1
                        ?.text1 || ""
                    )}
                  </p>
                  <br />

                  {DOCKER_WORKER?.installationProcedures[activeTab].step1
                    ?.text2 && (
                    <div className="code-sample text-[#14AE5C]">
                      <code>
                        {
                          DOCKER_WORKER?.installationProcedures[activeTab].step1
                            ?.text2
                        }
                      </code>
                      <CopyToClipboard
                        message={
                          DOCKER_WORKER?.installationProcedures[activeTab].step1
                            ?.text2
                        }
                        copyFunc={copyText}
                      />
                    </div>
                  )}

                  <br />
                  <p>
                    {parse(
                      DOCKER_WORKER?.installationProcedures[activeTab].step1
                        ?.text3 || ""
                    )}
                  </p>
                </div>
              </div>
            </NumberGlassCard>
            {/* Step 2 */}
            <NumberGlassCard
              src={
                DOCKER_WORKER?.installationProcedures[activeTab].step2?.number
              }
              divClassName="!bg-[#FFF] xl:max-w-[500px] !w-[100%] box-border mx-auto"
            >
              <div className="mt-[37px]  xl:max-w-[380px] px-[0] xl:px-[0] pb-[20px] xl:pb-[0px] xl:mx-auto flex flex-col justify-start items-center lg:items-start w-[100%]">
                <div className="!text-[black] text-center lg:text-left mb-[27px] w-[100%]">
                  <p>
                    {parse(
                      DOCKER_WORKER?.installationProcedures[activeTab].step2
                        ?.text1 || ""
                    )}
                  </p>
                  <br />

                  {DOCKER_WORKER?.installationProcedures[activeTab].step2
                    ?.text2 && (
                    <div className="code-sample text-[#14AE5C]">
                      <code>
                        {
                          DOCKER_WORKER?.installationProcedures[activeTab].step2
                            ?.text2
                        }
                      </code>
                      <CopyToClipboard
                        message={
                          DOCKER_WORKER?.installationProcedures[activeTab].step2
                            ?.text2
                        }
                        copyFunc={copyText}
                      />
                    </div>
                  )}
                  <br />
                  <p>
                    {
                      DOCKER_WORKER?.installationProcedures[activeTab].step2
                        ?.text3
                    }
                  </p>
                </div>
              </div>
            </NumberGlassCard>
            {/* Step 3 */}
            <NumberGlassCard
              src={
                DOCKER_WORKER?.installationProcedures[activeTab].step3?.number
              }
              divClassName="!bg-[#FFF] xl:max-w-[500px] !w-[100%] box-border mx-auto"
            >
              <div className="mt-[37px]  xl:max-w-[380px] px-[0] xl:px-[0] pb-[20px] xl:pb-[0px] xl:mx-auto flex flex-col justify-start items-center lg:items-start w-[100%]">
                <div className="!text-[black] text-center lg:text-left mb-[27px] w-[100%]">
                  <p>
                    {
                      DOCKER_WORKER?.installationProcedures[activeTab].step3
                        ?.text1
                    }
                  </p>
                  <br />

                  {DOCKER_WORKER?.installationProcedures[activeTab].step3
                    ?.text2 && (
                    <div className="code-sample text-[#14AE5C]">
                      <code>
                        {
                          DOCKER_WORKER?.installationProcedures[activeTab].step3
                            ?.text2
                        }
                      </code>
                      <CopyToClipboard
                        message={
                          DOCKER_WORKER?.installationProcedures[activeTab].step3
                            ?.text2
                        }
                        copyFunc={copyText}
                      />
                    </div>
                  )}
                  <br />
                  <p className="">
                    {
                      DOCKER_WORKER?.installationProcedures[activeTab].step3
                        ?.text3
                    }
                  </p>
                  <br />
                  {DOCKER_WORKER?.installationProcedures[activeTab].step3
                    ?.text4 && (
                    <div className="code-sample text-[#14AE5C]">
                      <code id="private-group-code">
                        {DOCKER_WORKER?.installationProcedures[activeTab].step3
                          ?.text4 || ""}
                      </code>
                      <CopyToClipboard
                        message={
                          DOCKER_WORKER?.installationProcedures[activeTab].step3
                            ?.text4
                        }
                        copyFunc={copyText}
                      />
                    </div>
                  )}
                  <br />
                  <p>
                    {
                      DOCKER_WORKER?.installationProcedures[activeTab].step3
                        ?.text5
                    }
                  </p>
                </div>
              </div>
            </NumberGlassCard>
          </div>
          {/* More Details Link */}
          <div className="p_large_style text-black text-center !px-[20px] ">
            {DOCKER_WORKER?.moreDetails.configurationOptions}
            <a
              target="_blank"
              rel="noreferrer"
              href={DOCKER_WORKER?.moreDetails.configurationOptionsLink}
              className="text-[#14AE5C] !underline"
            >
              {DOCKER_WORKER?.moreDetails.configurationOptionsLinkText}
            </a>
          </div>
        </CardPanel>
      </Tabs>
      {/* ------------------------------------------------- Footer ------------------------------------------------- */}
      <CTAPanel
        title={DOCKER_WORKER?.cta.title}
        subHeading={DOCKER_WORKER?.cta.subHeading}
        ctaText={DOCKER_WORKER?.cta.ctaText}
        ctaBgColour={DOCKER_WORKER?.cta.ctaBgColour}
        ctaTextColour={DOCKER_WORKER?.cta.ctaTextColour}
        bgImage={DOCKER_WORKER?.cta.bgImage}
        href={DOCKER_WORKER?.cta.href}
      ></CTAPanel>
      {/* ------------------------------------------------- Toast Notification ------------------------------------------------- */}

      <ToastContainer hideProgressBar={true} position="bottom-left" />
      {modalOn && (
        <ManifestYamlModal setModalOn={setModalOn} modalOn={modalOn} />
      )}
    </>
  );
}

export default DockerWorkers;
